import React from 'react';

export default function Tabs({ setActiveTab, activeTab }) {
  return (
    <div className="tabs-wrap">
      <p
        className={`tab ${activeTab === 'TotalCashInCirculation' ? 'active' : ''}`}
        onClick={() => setActiveTab('TotalCashInCirculation')}
      >
        TotalCashInCirculation
      </p>
      <p
        className={`tab ${activeTab === 'Denominations' ? 'active' : ''}`}
        onClick={() => setActiveTab('Denominations')}
      >
        Denominations
      </p>
    </div>
  );
}
