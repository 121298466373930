import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

export function YearRangePicker({Years,onStartYearSelect,onEndYearSelect,StartYear,EndYear}) {
  useEffect(()=>{

  },[Years,StartYear,EndYear]);  

  if(Years.length > 0){
    var startYearOptions = Years.filter((item) => item < Years[Years.length -1]);
    startYearOptions = startYearOptions.slice(0, startYearOptions.length - 1);
  //this will need to change on both Years[0]
   var endYearOptions = Years.filter((item) => item > Years[0]);
   endYearOptions = endYearOptions.slice(1);
  const handleStartYearChange = (event) => {
        const newStartYear = parseInt(event.target.value);  
        onStartYearSelect(newStartYear);
     };
  
    const handleEndYearChange = (event) => {
      const newEndYear = parseInt(event.target.value);    
       onEndYearSelect(newEndYear);        
      
    };

  return (
    <div className='select-box select-year-range'>
      <p>
        <span>Selected Year Range:</span> {StartYear} <span>to</span> {EndYear}
      </p>
      <div className='select-group'>
        <label htmlFor="startYearPicker">Select Start Year:</label>
        <select
          className='select'
          id="startYearPicker"
          onChange={handleStartYearChange}
          value={StartYear}
        >
          {startYearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className='select-group'>
        <label htmlFor="endYearPicker">Select End Year:</label>
        <select
          className='select'
          id="endYearPicker"
          onChange={handleEndYearChange}
          value={EndYear}
        >
          {endYearOptions.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
  }
}
