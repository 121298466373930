import React from 'react';
import CICTable from './CICTable'
import CiCGraphs from './CiCGraphs'

export default function CashInCirculation(props) {
  return (
  
    <>
      <div>
        <CICTable denominationtype = {"Notes"} country = {props.country} id={"CICNotesTable"} startyear={props.startyear} endyear ={ props.endyear}/>
        <CiCGraphs denominationtype = {"Notes"} country = {props.country} id={"CICNotesGraph"}  startyear={props.startyear} endyear ={ props.endyear}/>
        </div>
      <div>
        <CICTable denominationtype = {"Coins"} country = {props.country}  id={"CICCoinsNotesTable"}  startyear={props.startyear} endyear ={ props.endyear}/>
        <CiCGraphs denominationtype = {"Coins"} country = {props.country} id={"CICCoinsGraph"}   startyear={props.startyear} endyear ={ props.endyear}/>
      </div> 
    </> 
  )
}