import './Topnav.css'
import { NavLink } from 'react-router-dom'
import Logo from '../assets/img/logo.png'

export default function Topnav() {
  return (
    <>
        <div className='topnav'>
          <div className='logo'><NavLink to="/"><img src={Logo} alt="logo" /></NavLink></div>
          <div className='nav-wrap'>
              <NavLink to="/">Home</NavLink>
              <NavLink to="/datavault">DataVault</NavLink>
              <NavLink>Currencies</NavLink>
              <NavLink>About us</NavLink>
              <NavLink>Contact us</NavLink>
          </div>
          <div className='btn-wrap'>
              <NavLink to="/login" className='btn'>Login</NavLink>
              <NavLink to="/signup" className='btn'>Sign up</NavLink>
          </div>
        </div>
        {/* <div className='undernav'>
            <div className='logo'><NavLink to="/"><img src={Logo} alt="logo" /></NavLink></div>
            <div className='scrolling'>
              <marquee behavior="scroll" direction="left">
                <p>test<span>00</span><img src={Up} alt="up" /></p>
                <p>test<span>00</span><img src={Down} alt="dowm" /></p>
                <p>test<span>00</span><img src={Up} alt="up" /></p>
                <p>test<span>00</span><img src={Down} alt="dowm" /></p>
                <p>test<span>00</span><img src={Up} alt="up" /></p>
                <p>test<span>00</span><img src={Down} alt="dowm" /></p>
                <p>test<span>00</span><img src={Up} alt="up" /></p>
                <p>test<span>00</span><img src={Down} alt="dowm" /></p>
              </marquee>
            </div>
            <div className='language'>
              <div className='lang-circle'></div>
              <img src={DownArrow} alt="down" />
            </div>
        </div> */}
    </>
  )
}
