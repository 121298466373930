import './Bar.css'

export default function Bar() {
  return (
    <>
        <div className='select-box left'>
            <select className='select'>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
            </select>
            <select className='select'>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
            </select>
            <select className='select'>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
                <option>test</option>
            </select>
        </div>
    </>
  )
}
