import './Login.css'

export default function Login() {
  return (
    <div className='login-container'>
      <h1 className='temp'>LOGIN PAGE</h1>
      test test
    </div>
  )
}
