// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-container {
    
}
.temp {
    margin: 1rem;
    background: var(--purple);
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    padding: 1rem;
    border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/Login.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;IACI,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".login-container {\n    \n}\n.temp {\n    margin: 1rem;\n    background: var(--purple);\n    color: #fff;\n    width: fit-content;\n    padding: 1rem;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
