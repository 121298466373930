import React, { useState } from 'react';
import './Mobile.css';
import Burger from '../../assets/img/icons/burger.png';
import Close from '../../assets/img/icons/close.png';
import Logo from '../../assets/img/logo.png';
import { NavLink } from 'react-router-dom';

export default function MobileNav() {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <>
      <div className='burger-nav' onClick={toggleMobileNav}>
        <img src={isMobileNavOpen ? Close : Burger} alt={isMobileNavOpen ? 'Close' : 'Open'} />
      </div>
      <div className={`mobile-nav ${isMobileNavOpen ? 'open' : ''}`}>
        <div className='logo'>
          <NavLink to="/"><img src={Logo} alt="logo" /></NavLink>
        </div>
        <div className='nav-wrap'>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/datavault">DataVault</NavLink>
          <NavLink>Currencies</NavLink>
          <NavLink>About us</NavLink>
          <NavLink>Contact us</NavLink>
        </div>
      </div>
    </>
  );
}
