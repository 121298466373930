import React, { useState } from 'react';
import CICDenominationTable from './CICDenominationTable'
import CICDenominationGraph from './CICDenominationGraph'

export default function CICDenomination(props) {
  const [notesGraphLabels, setNotesGraphLabels] = useState([]);
  const [coinsGraphLabels, setCoinsGraphLabels] = useState([]);
  const [notesGraphColors, setNotesGraphColors] = useState([]);
  const [coinsGraphColors, setCoinsGraphColors] = useState([]);
  const [rowIndex,setRowIndex]= useState();
  const [columnIndex,setColumnIndex]= useState();
  const [isCoinsHovered,setisCoinsHovered]= useState(false);
  const [isNoteshovered,setIsNotesHovered]= useState(false);
 // const tableItemselected=(columnIndex,rowindex)=>{
  //  childRef.current.activateTooltip(2, 2);
  //  childRef.current.resetDatasetColors();
 // }
  const updateNotesGraphIndex=(columnindex,rowindex)=>{
    setRowIndex(rowindex);
    setColumnIndex(columnindex);
  }
  const updateNotesIsHovered=(value)=>{
    setIsNotesHovered(value)   
  }
  const updateCoinsGraphIndex=(columnindex,rowindex)=>{
    setRowIndex(rowindex);
    setColumnIndex(columnindex);
  }
  const updateCoinsIsHovered=(value)=>{
    setisCoinsHovered(value)
   
  }
  const handleNotesGraphUpdate = (labels, colors) => {
    if (!arraysEqual(notesGraphLabels, labels)) {
      setNotesGraphLabels(labels);
    }
    if (!arraysEqual(notesGraphColors, colors)) {
      setNotesGraphColors(colors);
    }
  };

  const handleCoinsGraphUpdate = (labels, colors) => {
    if (!arraysEqual(coinsGraphLabels, labels)) {
      setCoinsGraphLabels(labels);
    }
    if (!arraysEqual(coinsGraphColors, colors)) {
      setCoinsGraphColors(colors);
    }
  };
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  return (
  
  <>
    <div>
      <CICDenominationTable 
        denominationtype = {"Notes"} 
        country = {props.country}  
        startyear={props.startyear} 
        endyear ={ props.endyear} 
        graphLabels={notesGraphLabels} 
        graphColors={notesGraphColors} 
        updateGraphIndex ={updateNotesGraphIndex}
        updateishovered={updateNotesIsHovered}
      />
      <CICDenominationGraph 
        denominationtype = {"Notes"} 
        country = {props.country} 
        id={"CICNotesGraph"}  
        startyear={props.startyear} 
        endyear ={ props.endyear} 
        onGraphUpdate={handleNotesGraphUpdate}
        rowIndex={rowIndex} 
        columnIndex={columnIndex} 
        ishovered={isNoteshovered}
      />
    </div>
    <div>
      <CICDenominationTable 
        denominationtype = {"Coins"} 
        country = {props.country} 
        startyear={props.startyear} 
        endyear ={ props.endyear} 
        graphLabels={coinsGraphLabels} 
        graphColors={coinsGraphColors}
        updateGraphIndex ={updateCoinsGraphIndex}
        updateishovered={updateCoinsIsHovered} 
      />
      <CICDenominationGraph 
        denominationtype = {"Coins"} 
        country = {props.country} 
        startyear={props.startyear} 
        endyear ={ props.endyear} 
        onGraphUpdate={handleCoinsGraphUpdate}
        rowIndex={rowIndex} 
        columnIndex={columnIndex} 
        ishovered={isCoinsHovered} 
      />
    </div>
  </> 
  )
}