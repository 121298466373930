import React, { useEffect, useRef, useState, useMemo } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import { useCICCountryDenominationYearlyChange } from '../../../hooks/CIC/useCICCountryDenominationYearlyChange';
import configdata from '../../../appsettings.json';
import LoadingGraph from '../../loading/LoadingGraph';
// import LoadingGraph from '../../components/loading/LoadingGraph'
export default function CICDenominationGraph( props ) {

  const chartRef = useRef(null);
  //const [isLoading, setIsLoading] = useState(true);
 // const [graphData, setGraphData] = useState(null);
  const { CICCountryDenominationYearlyChange } = useCICCountryDenominationYearlyChange(
    props.country, props.denominationtype, props.startyear, props.endyear
  );

  const containsOnlyZerosAfterDecimal = value => /^\d+\.(0+)$/.test(value);
  const convertToIntegerIfNoDecimal = value => containsOnlyZerosAfterDecimal(value) ? parseInt(value) : value;
  const allYears = Array.from({ length: props.endyear - props.startyear + 1 }, (_, i) => props.startyear + i);
  const yearlyChangesByDenomination = CICCountryDenominationYearlyChange.reduce((acc, item) => {
     const denomination = convertToIntegerIfNoDecimal(item.Denomination);
     if (!acc[denomination]) {
        acc[denomination] = allYears.map(() => 0);
     }
     const yearIndex = item.Year - props.startyear;
     acc[denomination][yearIndex] = item['Yearly Change'] ?? 0;
     return acc;
  }, {});

  useEffect(()=>{
    if(props.ishovered=== true){
        activateTooltip(props.columnIndex,props.rowIndex)
    }
    else{
      closeTooltip();
    }
   
  },[yearlyChangesByDenomination]);
  
  const uniqueYears = [...new Set(CICCountryDenominationYearlyChange.map(item => item.Year))]
    .sort((a, b) => a - b);

  const sortedDenominations = Object.keys(yearlyChangesByDenomination).sort((a, b) => parseFloat(a) - parseFloat(b));

  const datasets = sortedDenominations.map(denomination => {
    const yearlyChanges = yearlyChangesByDenomination[denomination];
    return {
      label: denomination,
      data: yearlyChanges,
      fill: false,
      borderColor: configdata.GraphBackGroundColors[sortedDenominations.indexOf(denomination) % configdata.GraphBackGroundColors.length],
      borderWidth: 1.2,
      pointRadius: 2,
      pointBackgroundColor: configdata.GraphPointColors[sortedDenominations.indexOf(denomination) % configdata.GraphBackGroundColors.length],
      backgroundColor: configdata.GraphPointColors[sortedDenominations.indexOf(denomination) % configdata.GraphPointColors.length],
      tension: 0.3,
    };
  });
  const [selectedButton, setSelectedButton] = useState(null);

  const toggleData = label => {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;

    const datasetIndex = datasets.findIndex(dataset => dataset.label === label);
    if (datasetIndex === -1) return;

    const dataset = chartInstance.data.datasets[datasetIndex];
    dataset.hidden = !dataset.hidden;
    chartInstance.update();

    setSelectedButton(selectedButton === label ? null : label);
  };

  const onClick = event => {
    //console.log(getElementsAtEvent(chartRef.current, event));
  };
 
  function activateTooltip(datasetIndex, pointIndex) {
    const chartInstance = chartRef.current;
  
    if (!chartInstance) return;
  
    // Find the position of the point
    const pointMeta = chartInstance.getDatasetMeta(datasetIndex).data[pointIndex];
    if (!pointMeta) return;
  
    // Temporarily update dataset colors
    chartInstance.data.datasets.forEach((dataset, index) => {
      if (index === datasetIndex) {
        // Highlight the selected dataset (keep its original color)
        // Assumes original colors are stored in the dataset 
        dataset.borderWidth = 2;
      } else {
        // Store original colors if not already stored
        if (!dataset.originalBorderColor) {
          dataset.originalBorderColor = dataset.borderColor;
          dataset.originalBackgroundColor = dataset.backgroundColor;
        }
        // Gray out other datasets
        dataset.borderColor = '#D7DBDD ';
        dataset.backgroundColor = '#F2F3F4 ';  
      }
    });  
    // Simulate hover event
    chartInstance.tooltip.setActiveElements([{ datasetIndex, index: pointIndex }], {
      x: pointMeta.x, 
      y: pointMeta.y
    });
    // Update chart to show tooltip and temporary color changes
    chartInstance.update();
  }

  function closeTooltip() {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;

    chartInstance.tooltip.setActiveElements([], {});
    chartInstance.update();
  }

  const data = {
    labels: uniqueYears,
    datasets,
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Yearly Change In %',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Yearly Change for ' + props.denominationtype + " Volume",
        font: {
          size: 16,
        },
      }, ticks: {
        callback: function(value, index) {
          // Hide the first label
          return index === 0 ? '' : value;
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.parsed.y !== null ? context.parsed.y + ' %' : '';
            const datasetIndex = context.datasetIndex;
            const dataIndex = context.dataIndex;
            if (datasetIndex !== undefined && dataIndex !== undefined) {
              const denomination = datasets[datasetIndex].label;
              label = ` ${denomination}: ${label}`;
            }
            return label;
          },
        },
      },
    },
  };
  
  const legendValues = useMemo(() => datasets.map(dataset => dataset.label), [datasets]);
  const backgroundColors = useMemo(() => datasets.map(dataset => dataset.borderColor), [datasets]);

  useEffect(() => {
    if (legendValues.length > 0 && backgroundColors.length > 0) {
      props.onGraphUpdate(legendValues, backgroundColors);
    }
    
  }, [legendValues, backgroundColors, props]);
  
  return (
    <> {CICCountryDenominationYearlyChange.length === 0 ? (
      <LoadingGraph />
    ) : (
      <div className="graph">
        <Chart
          type="line"
          data={data}
          options={options}
          ref={chartRef}
          onClick={onClick}
        />
      </div>
    )}
    </>
  );
}
