import React from 'react';
import { useCICDenominations } from '../../../hooks/CIC/useCICDenominations';
import LoadingTbl from '../../loading/LoadingTbl';

export default function CICDenominationTable(props) {
  const { country, denominationtype, startyear, endyear, graphLabels, graphColors } = props;
  
  // Function to format numbers with 3 decimal places and add commas
  const cleanDigits = (number) => {
    return Number(number) < 0
      ? Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      : Number(number).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const { CICDenominations } = useCICDenominations(
    country,
    denominationtype,
    startyear,
    endyear,
  );

  const allColumnNames = CICDenominations.reduce((columns, item) => {
    Object.keys(item).forEach(columnName => columns.add(columnName));
    return columns;
  }, new Set());

  const multiplierValue = CICDenominations.length > 0 ? CICDenominations[0].Multiplier : "";

  const handleMouseEnter = (columnIndex, rowIndex) => {
    const updateIsHovered = props.updateishovered || (() => {});
    updateIsHovered(true);
    props.updateGraphIndex(rowIndex, columnIndex);
  };

  const handleMouseLeave = () => {
    const updateIsHovered = props.updateishovered || (() => {});
    updateIsHovered(false);
  };

  return (
    <>
      {CICDenominations.length === 0 ? (
        <LoadingTbl />
      ) : (
        <table className='CIC-Tabledata table table-effect'>
          <label className='denom-title'>
            {denominationtype}
            <span className='multiplier'>Volume in {multiplierValue}</span>
          </label>
          <thead>
            <tr>
              <th>Year</th>
              {graphLabels
                .map(label => parseFloat(label))
                .sort((a, b) => a - b)
                .map((label, index) => (
                  <th 
                    key={index} 
                    style={{ backgroundColor: graphColors[index] }}
                  >
                    {label}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {CICDenominations.map((item, columnIndex) => (        
              <tr key={item.Year_id}>
                <td>{item.Year}</td>
                {Array.from(allColumnNames).filter(column => column !== "Year" && column !== "Multiplier").map((column, rowIndex) => (
                  <td 
                    key={`${item.Year_id}-${column}-${rowIndex}`} 
                    onMouseLeave={handleMouseLeave} 
                    onMouseEnter={() => handleMouseEnter(columnIndex, rowIndex)}
                  >
                    {cleanDigits(item[column])}
                  </td>
                ))}            
              </tr>
            ))}         
          </tbody>
        </table>
      )}
    </>
  );
}
