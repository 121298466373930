import { useState, useEffect } from 'react';
import Axios from 'axios';
import configdata from '../../appsettings.json';
export  const useGetCountriesMultipliers = (initialList) => {
    const CountriesString = initialList.toString();
    const [countriesmultiplierList, setCountriesmultiplierList] = useState([]);
    const [error, setError] = useState(null);
    var url = configdata.apiServer_Url + "GetCICCountriesMultipliersByCountries";

    useEffect(() =>{
        const fetchData = async () => {
            try{
                const response = await Axios.post(url,{ countries: CountriesString});
                setCountriesmultiplierList(response.data)
            } catch(error) {
                setError(error)
            }
        };
        if(CountriesString != "")
        {
           fetchData();
        }
    }, [initialList])

    return{ countriesmultiplierList ,error};
}
