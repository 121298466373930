import { useState, useEffect } from 'react';
import Axios from 'axios';
import configdata from '../../appsettings.json';
export const useCICComparisonCountries = (initialList) => {
    const countriesComparisonString = initialList.toString();
    const [CountriesComparisonList, setCountriesComparisonList] = useState([]);

    const [error, setError] = useState(null);
    const apiURL = configdata.apiServer_Url +"GetCICComparisonCountries";

    useEffect(() =>{
     
        const fetchData = async () => {
            try{
                const response = await Axios.post(apiURL, {denominationtype: "Notes", countries: countriesComparisonString});
                setCountriesComparisonList(response.data)
            } catch(error) {
                setError(error)
            }
        };
      
        if(countriesComparisonString != "" )
        {
            fetchData();           
        }
       
    }, [initialList])

    return{ CountriesComparisonList,error};
} 
