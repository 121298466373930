
import axios from 'axios';
import { useState, useEffect } from 'react';
import configdata from '../../appsettings.json';
export const useYears = (country) => {
  const [error, setError] = useState(null);
  const [Years, setYears] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
       var url = configdata.apiServer_Url +"Years";
       const data = { Country: country };
       const response = await axios.post(
          url,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );
        setYears(response.data);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function on component mount or when 'country' changes.
  }, [country]);
  return { Years, error };
};
