// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LOADING SPINNER */
.loading-spinner {
    height: 50px;
    width: 50px;
    position: relative;
  }
  .loading-spinner > img {
    width: 100%;
    position: absolute;
    inset: 0;
    top: 12px;
    left: 2px;
    margin: auto !important;
    animation: spin 1.4s infinite;
    filter: invert(.8);
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/loading/LoadingSpinner.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;EACpB;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,SAAS;IACT,uBAAuB;IACvB,6BAA6B;IAC7B,kBAAkB;EACpB;EACA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["/* LOADING SPINNER */\n.loading-spinner {\n    height: 50px;\n    width: 50px;\n    position: relative;\n  }\n  .loading-spinner > img {\n    width: 100%;\n    position: absolute;\n    inset: 0;\n    top: 12px;\n    left: 2px;\n    margin: auto !important;\n    animation: spin 1.4s infinite;\n    filter: invert(.8);\n  }\n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
