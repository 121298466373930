
import axios from 'axios';
import { useState, useEffect } from 'react';
import configdata from '../../appsettings.json';
export const useCountries = () => {
  const [error, setError] = useState(null);
  const [Countries, setCountries] = useState([]);
  const[loadonce,setloadonce] = useState(true)
  useEffect(() => {
    setloadonce(false)
    const fetchData = async () => {
      try {
        var url = configdata.apiServer_Url +"Countries";
        const response = await axios.get(
          url,
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );
        setCountries(response.data);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    if(loadonce === true){      
      fetchData(); // Call the fetchData function on component mount or when 'country' changes.    
    }
    
  }, []);
  return { Countries, error };
};