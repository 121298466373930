import './Datavault.css'
import Sidenav from '../../components/Sidenav';
import Overview from '../overview/Overview';

export default function Datavault() {
  return (
    <div className='dv-container'>
        <Sidenav />
        <div className='dv-main'>
            <div className='head'>
                <div className='header'>
                    <h2>Welcome to the
                        <span>DataVault</span>
                    </h2>
                    <h1>Overview Dashboard</h1>
                </div>
            </div>
            <div className='body'>
                <Overview />
            </div>
        </div>
    </div>
  )
}
