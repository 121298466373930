// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popover {
    position: absolute !important;
    top: .4rem;
    right: 0;
    z-index: 100;
}
.popover::after {
    display: none;
}
.popover-btn {
    background: none;
    cursor: pointer;
    transition: .2s ease-in-out;
}
.popover-btn:hover {
    opacity: .6;
}
.popover-btn > img {
    height: 25px;
}
.popover-content {
    background: #f9f9f9;
    border: 1px solid #4BADF2;
    border-radius: 4px;
    padding: .2rem .2rem .4rem;
    position: absolute;
    top: 0;
    right: 85%;
    box-shadow: var(--shadow);
    font-size: .9rem;
    min-width: 250px;
}
.popover-header {
    border-bottom: 1px solid #4BADF2;
    border-radius: 2px;
    padding: .2rem;
    background: #4BADF2;
    color: #fff;
}
.popover-body {
    padding: .2rem;
    padding-bottom: 0;
}
.popover-body img {
    min-height: 50px;
    height: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/ToolTips/Popover.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,UAAU;IACV,QAAQ;IACR,YAAY;AAChB;AACA;IACI,aAAa;AACjB;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,2BAA2B;AAC/B;AACA;IACI,WAAW;AACf;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,kBAAkB;IAClB,0BAA0B;IAC1B,kBAAkB;IAClB,MAAM;IACN,UAAU;IACV,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;AACpB;AACA;IACI,gCAAgC;IAChC,kBAAkB;IAClB,cAAc;IACd,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".popover {\n    position: absolute !important;\n    top: .4rem;\n    right: 0;\n    z-index: 100;\n}\n.popover::after {\n    display: none;\n}\n.popover-btn {\n    background: none;\n    cursor: pointer;\n    transition: .2s ease-in-out;\n}\n.popover-btn:hover {\n    opacity: .6;\n}\n.popover-btn > img {\n    height: 25px;\n}\n.popover-content {\n    background: #f9f9f9;\n    border: 1px solid #4BADF2;\n    border-radius: 4px;\n    padding: .2rem .2rem .4rem;\n    position: absolute;\n    top: 0;\n    right: 85%;\n    box-shadow: var(--shadow);\n    font-size: .9rem;\n    min-width: 250px;\n}\n.popover-header {\n    border-bottom: 1px solid #4BADF2;\n    border-radius: 2px;\n    padding: .2rem;\n    background: #4BADF2;\n    color: #fff;\n}\n.popover-body {\n    padding: .2rem;\n    padding-bottom: 0;\n}\n.popover-body img {\n    min-height: 50px;\n    height: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
