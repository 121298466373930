
import axios from 'axios';
import { useState, useEffect } from 'react';
import configdata from '../../appsettings.json';
export const useToolTipPost = (fragment) => {
  const [error, setError] = useState(null);
  const [post, setPost] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
       
        var data ={
                     query: "query { fragmentBy(slug: \""+ fragment +"\") { header content } }"
                   }
          
        var url = configdata.graphqlServer_Url;
        const response = await axios({
            method:'post',
          url:url, 
            headers: {
            'Content-Type': 'application/json',
          },       
         data: JSON.stringify(data),
        });
        setPost(response.data.data.fragmentBy);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function on component mount or when 'country' changes.
  }, [fragment]);
  return { post, error };
};