import React, { useState} from 'react';

import CICMarketDataFilter from '../../components/CIC/CICMarketData/CICMarketDataFilter';
import Sidenav from '../../components/Sidenav';
import '../cicmarketdata/cicmarketdata.css';
import Popover from '../../components/ToolTips/Popover';
import CICMarketDataTable from '../../components/CIC/CICMarketData/CICMarketDataTable'
import CICMarketDataGraph from '../../components/CIC/CICMarketData/CICMarketDataGraph'
//IMAGES

//LOADING
import LoadingEllipsis from '../../components/loading/LoadingEllipsis';
export default function CICMarketData(props) {
    const [notesGraphLabels, setNotesGraphLabels] = useState([]);
    const [notesGraphColors, setNotesGraphColors] = useState([]);
    const [rowIndex,setRowIndex]= useState();
    const [columnIndex,setColumnIndex]= useState();
    const [isNoteshovered,setIsNotesHovered]= useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [fragment] = useState("cic-market-info");
    const [FilteredNotestableList, setFilteredNotesTableList] = useState([]);
    const [FilteredNotesGraphList, setFilteredNotesgraphList] = useState([]);
    const handleLoadingChange = (loadingStatus) => {
        setIsLoading(loadingStatus);
    };
    const handlefilteredList = (tablelist,graphlist) => {
      setFilteredNotesTableList(tablelist);
      setFilteredNotesgraphList(graphlist)
      setIsLoading(false);
    };
    const updateNotesGraphIndex=(columnindex,rowindex)=>{
      setRowIndex(rowindex);
      setColumnIndex(columnindex);

    }
  
    const updateNotesIsHovered=(value)=>{
      setIsNotesHovered(value)   
    }
    const handleNotesGraphUpdate = (labels, colors) => {
      if (!arraysEqual(notesGraphLabels, labels)) {
        setNotesGraphLabels(labels);
      }
      if (!arraysEqual(notesGraphColors, colors)) {
        setNotesGraphColors(colors);
      }
    };
    const arraysEqual = (a, b) => {
      if (a === b) return true;
      if (a == null || b == null) return false;
      if (a.length !== b.length) return false;
  
      for (let i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
      }
      return true;
    }
  return (
    <div className="dv-container cic-container">
      <Sidenav />
      <div className="dv-main">
        <div className="head">
          <div className="header">
            <h2>
              Welcome to the
              <span>DataVault</span>
            </h2>
            <h1>CIC Market Data</h1>
          </div>
        </div>
        <div className="body"> 
          <div className="bar">
            <CICMarketDataFilter
              onLoadingChange ={handleLoadingChange} 
              onFilterUpdate={handlefilteredList}
            />
            </div>
          {isLoading ? ( 
            <LoadingEllipsis />
          ) : (
            <>
              <div className='tables-wrap market-tables-wrap'>            
                <Popover 
                  header="Popover" 
                  content="The content for the popover goes here." 
                  fragment={fragment}
                />
                <div>
                  <CICMarketDataTable 
                    isloading={isLoading}
                    list={FilteredNotestableList}
                    graphLabels={notesGraphLabels} 
                    graphColors={notesGraphColors} 
                    updateGraphIndex ={updateNotesGraphIndex}
                    updateishovered={updateNotesIsHovered}
                  />
                  <CICMarketDataGraph 
                    isloading={isLoading}
                    rowIndex={rowIndex} 
                    columnIndex={columnIndex} 
                    ishovered={isNoteshovered} 
                    filteredList={FilteredNotesGraphList}
                    onGraphUpdate={handleNotesGraphUpdate}
                    denominationtype={"Notes"}
                  />
                </div>
              </div>
            </>
          )}
       
      </div>
    </div>
    </div>
  );
}
