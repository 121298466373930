import React, { useEffect, useState, useRef } from 'react';

export default function DropDownList({ onSelect, list, listname }) {
    const [selectedItem, setSelectedItem] = useState('All');
    const isInitialMount = useRef(true); // useRef to track the initial mount

    useEffect(() => {
        // Run only once after the initial render
        if (isInitialMount.current) {
         
            if (list.length > 0) {
               
                onSelect(list); // Call onSelect with the entire list 
                 isInitialMount.current = false; // Mark as no longer the initial mount
            }
        }
    }, [list, onSelect]); // Dependency array includes list and onSelect

    const handleChange = (e) => {
        const newSelectedItem = e.target.value;
        setSelectedItem(newSelectedItem);

        if (newSelectedItem === "All") {
            onSelect(list); // Pass the entire list if "All" is selected
        } else {
            onSelect(newSelectedItem); // Pass the selected item
        }
    };

    return (
        <>
          <label htmlFor="">Select {listname}</label>
            <select className='select' value={selectedItem} onChange={handleChange}>
                <option value="All">All</option>
                {list.map((item, index) => (
                    <option key={index} value={item}>
                        {item}
                    </option>
                ))}
            </select>   
        </>
    );
}
