import React, { useEffect, useState } from 'react';
import '../../../pages/ciccomparison/ciccomparison.css';
import DropDownList from '../../CIC/DropDownList';
import { useCICRegions } from '../../../hooks/CIC/useCICRegions';
import { useCICSubRegions } from '../../../hooks/CIC/useCICSubRegions';
import { useCountriesBySubRegion } from '../../../hooks/CIC/useCountriesBySubRegion';
import {MultiselectDropdownList} from '../../CIC/MultiselectDropdownList'
import {YearRangePicker} from '../../../components/CIC/CICComparison/YearRangePicker'
import { useCICComparisonCountries } from '../../../hooks/CIC/useCICComparisonCountries';
import { useCICCountriesByCountriesYearlyChange } from '../../../hooks/CIC/useCICCountriesByCountriesYearlyChange';
import {useGetCountriesMultipliers} from '../../../hooks/CIC/useGetCountriesMultipliers'
export default function CICComparisonFilters(props) {
    const [Region,setRegion] = useState(''); 
    const [StartDate,setStartDate] = useState(''); 
    const [EndDate,setEndDate] = useState(''); 
    const [Years,setYears] =useState('');
    const [startyear, setStartYear] = useState('');
    const [endyear, setEndYear] = useState('');
    const [SubRegions,setSubRegions] = useState([]);
    const [Countries,setCountries] = useState([]);
    const{CICRegions,getregionserror} =useCICRegions();
    const{CICSubRegions,getsubregionserror} =useCICSubRegions(Region);
    const{CICCountries,getcountrieserror} =useCountriesBySubRegion(SubRegions);
    const {countriesmultiplierList,countriesmultiplierListerror} =useGetCountriesMultipliers(Countries);
    const{CountriesComparisonList,compasionerror} = useCICComparisonCountries(Countries);
    const{countriesYearlyChange,compasionyearlychangeerror} = useCICCountriesByCountriesYearlyChange(Countries);   
    
    const [iscountrieslistchanged, setiscountrueslistchanged] = useState(false);
    const [issubregionslistchanged, setissubregionslistchanged] = useState(false);
    //const [issubregionchanged, setissubregionchanged] = useState(false);

    const handleRegionSelect = (selectedRegion) => {
      props.onLoadingChange(true);
      setRegion(selectedRegion);
  //    setislistchanged(true);
      
    };
    const handleSubRegionsSelect = (selectedsubregions) => {
      props.onLoadingChange(true);
      setSubRegions(selectedsubregions.map(data => data.value));    
    };
    const handleCountriesSelect = (selectedcountries) => {
      props.onLoadingChange(true);
    setCountries(selectedcountries.map(data => data.value));
 
    };
   
    const handleStartYearSelect = (selectedYear) => {
      setStartYear(prevYear => selectedYear);
    };
 
    const handleEndYearSelect = (selectedYear) => {
      setEndYear(prevYear => selectedYear);
    };
    useEffect(() => {
      var uniqueYears = [...new Set(CountriesComparisonList.map(item => item.Year))]
      .sort((a, b) => a - b);
      setYears(uniqueYears);
    }, [CountriesComparisonList,countriesYearlyChange]);
    useEffect(() => {setiscountrueslistchanged(true)},[CICCountries ])
    useEffect(() => {setissubregionslistchanged(true)},[CICSubRegions ])
    useEffect(() => {

      if (CountriesComparisonList.length > 0 && startyear !='' && endyear !=''  && countriesYearlyChange.length > 0) {
        const filteredTableList = CountriesComparisonList.filter(item => {
        const itemYear = parseInt(item.Year, 10);
          return itemYear >= parseInt(startyear, 10) && itemYear <= parseInt(endyear, 10);
        });
        const filteredNotesGraphList = countriesYearlyChange.filter(item => {

          const itemYear = parseInt(item.Year, 10);
          return itemYear >= parseInt(startyear, 10) && itemYear <= parseInt(endyear, 10);
        });
      
        props.onFilterUpdate(filteredTableList, filteredNotesGraphList,countriesmultiplierList,iscountrieslistchanged);
      }
   
    }, [startyear,endyear,CountriesComparisonList, countriesYearlyChange,]);

  useEffect(() => {
    if (Years.length > 0) {
        setStartYear(Years[0]);
        setEndYear(Years[Years.length - 1]);
    }  
}, [Years]);

  return (
    <div className="big-select-wrap">
      <div className='top-left grid-select'>
      <DropDownList 
         onSelect={handleRegionSelect} 
         list={CICRegions.map(data => data.Name)} 
         listname ={"Regions"} 
      />
      </div>
      <div className='bottom-left grid-select'>
      <MultiselectDropdownList
        setislistchanged={setissubregionslistchanged}
        islistchanged={issubregionslistchanged}
         onSelect={handleSubRegionsSelect}
         list={CICSubRegions.map(data => data.SubRegion)}
         listname ={"SubRegions"}
      />
      </div>
      <div className='bottom-right grid-select'>
      <MultiselectDropdownList 
        setislistchanged={setiscountrueslistchanged}
        islistchanged={iscountrieslistchanged}
        onSelect={handleCountriesSelect} 
        list={CICCountries.map(data => data.Country)} 
        listname ={"Countries"}
      />
      </div>
      <YearRangePicker 
        onStartYearSelect={handleStartYearSelect}
        onEndYearSelect={handleEndYearSelect}
        StartYear={startyear}
        EndYear={endyear}
        Years ={Years}
      />
    </div>
  );
}