import React, { useEffect, useState } from 'react';
import '../../../pages/ciccomparison/ciccomparison.css';
import CICCountriesDropDown from '../../../components/CIC/CICMarketData/CICCountriesDropDown';
import YearRangePicker      from '../../../components/CIC/CICMarketData/YearRangePicker';
import { useCICMarketDataByCountry } from '../../../hooks/CIC/useCICMarketDataByCountry';
import { useCICGetMarketDataByCountryYearlyChange } from '../../../hooks/CIC/useCICGetMarketDataByCountryYearlyChange';
export default function CICComparisonFilters(props) {
    const [Years,setYears] =useState('');
    const [startyear, setStartYear] = useState('');
    const [endyear, setEndYear] = useState('');
    const [country,setCountry] = useState('');

    const [iscountrieslistchanged, setiscountrueslistchanged] = useState(false);
    const { cicmarketDataList, tableerror } = useCICMarketDataByCountry(country);
    const { cicmarketDataYearlyChangeList, grapherror } = useCICGetMarketDataByCountryYearlyChange(country);
    
      useEffect(() => {
                  setYears(cicmarketDataList.map(years =>years.Year));      
      }, [cicmarketDataList]);

      useEffect(() => {

        if (cicmarketDataList.length > 0 && startyear !='' && endyear !='' && cicmarketDataYearlyChangeList.length > 0) {
          
          const filteredNotesTableList = cicmarketDataList
          .filter(item => {
            const itemYear = parseInt(item.Year, 10);
            return itemYear >= parseInt(startyear, 10) && itemYear <= parseInt(endyear, 10);
          })
          .sort((a, b) => parseInt(a.Year, 10) - parseInt(b.Year, 10));
          const filteredNotesGraphList = cicmarketDataYearlyChangeList.map(metricData => {
            const filteredMetricData = { Metric: metricData.Metric };
            for (const year in metricData) {
              if (metricData.hasOwnProperty(year) && year !== 'Metric') {
                const yearInt = parseInt(year, 10);
                if (yearInt >= parseInt(startyear, 10) && yearInt <= parseInt(endyear, 10)) {
                  filteredMetricData[year] = metricData[year];
                }
              }
            }  
            return filteredMetricData;
          });
         
          props.onFilterUpdate(filteredNotesTableList,filteredNotesGraphList);
        }
      }, [startyear,endyear,cicmarketDataList,cicmarketDataYearlyChangeList]);
  
    const handleCountrySelect = (selectedcountry) => {
      props.onLoadingChange(true);
      setCountry(selectedcountry);
 
    };
   
    const handleStartYearSelect = (selectedYear) => {
        setStartYear(prevYear => selectedYear);
      };
   
      const handleEndYearSelect = (selectedYear) => {
        setEndYear(prevYear => selectedYear);
      };

    useEffect(() => {setiscountrueslistchanged(true)},[country ])
    useEffect(() => {
    if (Years.length > 0) {
        setStartYear(Years[0]);
        setEndYear(Years[Years.length - 1]);
    }  
}, [Years]);

  return (
    <div className="big-select-wrap">
      <div className='top-left grid-select'>
      <CICCountriesDropDown 
         onCountrySelect={handleCountrySelect} 
         listname ={"Country"} 
      />
      </div>
      <YearRangePicker 
        onStartYearSelect={handleStartYearSelect}
        onEndYearSelect={handleEndYearSelect}
        StartYear={startyear}
        EndYear={endyear}
        Years ={Years}
      />
    </div>
  );
}