import { useEffect, useState } from 'react';
import { useCountries } from '../../../hooks/CIC/useCountries';

export default function CICCountriesDropDown( {onCountrySelect, country }) {
    const { Countries, cicerror } = useCountries();
    const [selectedCountry, setSelectedCountry] = useState(country || '');

    useEffect(() => {
        if (Countries.length > 0 && !selectedCountry) {
            const initialCountry = Countries[0].Name;
            setSelectedCountry(initialCountry);
            onCountrySelect(initialCountry);
        }
    }, [Countries]);

    const handleChange = (e) => {
        const newCountry = e.target.value;
        setSelectedCountry(newCountry);
        onCountrySelect(newCountry);
    };

    return (
        <>
            <select className='select' value={selectedCountry} onChange={handleChange}>
                <option value="">Select Country</option>
                {Countries.map((item) => (
                    <option key={item.Name} value={item.Name}>
                        {item.Name}
                    </option>
                ))}
            </select>
            {cicerror && <p>Error loading countries.</p>}
        </>
    );
}
