
import axios from 'axios';
import { useState, useEffect } from 'react';
import configdata from '../../appsettings.json';
export const useCICRegions = () => {
  const [error, setError] = useState(null);
  const [CICRegions, setCICRegions] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        var url = configdata.apiServer_Url +"GetCiCRegions";
        const response = await axios.get(
          url,
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );
        setCICRegions(response.data);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function on component mount or when 'country' changes.
  }, []);
  return { CICRegions, error };
};