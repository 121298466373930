// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-tbl {
    min-width: 560px;
    min-height: 300px;
    display: flex;
    flex-direction: column;
}
.loading-tbl > div {
    flex: 1 1;
    border-bottom: 1px solid rgba(39, 42, 55, 0.05);
}
.loading-tbl > div:nth-child(even) {
    background-color: var(--light-alt);
}`, "",{"version":3,"sources":["webpack://./src/components/loading/LoadingTbl.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,SAAO;IACP,+CAA+C;AACnD;AACA;IACI,kCAAkC;AACtC","sourcesContent":[".loading-tbl {\n    min-width: 560px;\n    min-height: 300px;\n    display: flex;\n    flex-direction: column;\n}\n.loading-tbl > div {\n    flex: 1;\n    border-bottom: 1px solid rgba(39, 42, 55, 0.05);\n}\n.loading-tbl > div:nth-child(even) {\n    background-color: var(--light-alt);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
