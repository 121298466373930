import '../../../pages/cicmarketdata/cicmarketdata.css';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import 'chart.js/auto';
import configdata from '../../../appsettings.json';
import LoadingGraph from '../../loading/LoadingGraph';
import { Line } from 'react-chartjs-2';
import {Chart} from'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);
export default function CICMarketDatagraph(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [chartKey, setChartKey] = useState(0); // State to track the chart key
  const chartRef = useRef(null);

  useEffect(() => {
    if (props.ishovered) {
      activateTooltip(props.columnIndex, props.rowIndex); 
    } else {
      closeTooltip();
    }

  }, [props.ishovered, props.rowIndex, props.columnIndex]);

  // Extracting years (x-axis labels)
  const years = Object.keys(props.filteredList[0]).filter(key => key !== 'Metric');

  // Transforming data into datasets
  const datasets = props.filteredList.map(metricData => {
    let yAxisID = 'y-axis-other'; // Default assignment

    // Ensure metricData contains an array of values for each year
    const values = years.map(year => parseFloat(metricData[year]));
    yAxisID = values.some(value => value > 150) ? 'y-axis-large-numbers' : 'y-axis-other';

    return {
      label: metricData.Metric,
      data: values,
      yAxisID: yAxisID,
      borderColor: configdata.GraphBackGroundColors[props.filteredList.indexOf(metricData) % configdata.GraphBackGroundColors.length],
      pointBackgroundColor: configdata.GraphPointColors[props.filteredList.indexOf(metricData) % configdata.GraphBackGroundColors.length],
      backgroundColor: configdata.GraphPointColors[props.filteredList.indexOf(metricData) % configdata.GraphPointColors.length],
      fill: false,
      borderWidth: 1.2,
      pointRadius: 2,
      tension: 0.3,
    };
  });
  const data = {
    labels: years,
    datasets: datasets
  };
  const pointLineDistance = (pointX, pointY, lineStartX, lineStartY, lineEndX, lineEndY) => {
    const A = pointX - lineStartX;
    const B = pointY - lineStartY;
    const C = lineEndX - lineStartX;
    const D = lineEndY - lineStartY;

    const dot = A * C + B * D;
    const lenSq = C * C + D * D;
    const param = lenSq !== 0 ? dot / lenSq : -1;

    let xx, yy;

    if (param < 0) {
      xx = lineStartX;
      yy = lineStartY;
    } else if (param > 1) {
      xx = lineEndX;
      yy = lineEndY;
    } else {
      xx = lineStartX + param * C;
      yy = lineStartY + param * D;
    }

    const dx = pointX - xx;
    const dy = pointY - yy;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Highlight Y-axis function
  const highlightYAxis = (yAxisValue, yAxisID, color) => {
    const chart = chartRef.current;
    if (!chart) return;

    const ctx = chart.ctx;
    const yAxis = chart.scales[yAxisID];

    const yPixel = yAxis.getPixelForValue(yAxisValue);

    ctx.save();
    ctx.strokeStyle = color; // Use the color of the dataset
    ctx.lineWidth = 2;
    ctx.beginPath();
    ctx.moveTo(yAxis.left, yPixel);
    ctx.lineTo(yAxis.right, yPixel);
    ctx.stroke();
    ctx.restore();
  };
  // Chart options
  const options = {
    scales: {
      x: {
        type: 'category',
      },
      'y-axis-large-numbers': {
        type: 'linear',
        display: true,
        position: 'left',
        beginAtZero: false,
        ticks: {
          stepSize: 50000, // Adjust this value as needed for granularity
        }
      },
      'y-axis-other': {
        type: 'linear',
        display: true,
        position: 'right',
        beginAtZero: true,
      },

    },   
    responsive: true,
    maintainAspectRatio: true,onHover: (event) => {
      const chart = chartRef.current;
      if (!chart) return;

      const rect = event.native.target.getBoundingClientRect();
      const mouseX = event.native.clientX - rect.left;
      const mouseY = event.native.clientY - rect.top;

      let minDistance = Number.MAX_VALUE;
      let closestDatasetIndex = -1;
      let closestDataIndex = -1;

      chart.data.datasets.forEach((dataset, datasetIndex) => {
        const meta = chart.getDatasetMeta(datasetIndex);
        if (!meta.hidden) {
          meta.data.forEach((point, index) => {
            if (index < meta.data.length - 1) {
              const nextPoint = meta.data[index + 1];
              const distance = pointLineDistance(mouseX, mouseY, point.x, point.y, nextPoint.x, nextPoint.y);
              if (distance < minDistance) {
                minDistance = distance;
                closestDatasetIndex = datasetIndex;
                closestDataIndex = index;
              }
            }
          });
        }
      });

      chart.ctx.clearRect(0, 0, chart.width, chart.height);
      chart.draw();

      if (closestDatasetIndex !== -1 && minDistance <= 5) { // 5px as the threshold for proximity
        const yAxisValue = chart.data.datasets[closestDatasetIndex].data[closestDataIndex];
        const yAxisID = chart.data.datasets[closestDatasetIndex].yAxisID;
        const datasetColor = chart.data.datasets[closestDatasetIndex].borderColor;
        highlightYAxis(yAxisValue, yAxisID, datasetColor);
      }
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: function(context) {
            let label = context.dataset.label || '';
            let value = context.parsed.y;
  
            if (label === 'Inflation') {
              return `${label}: ${value}%`;
            } else {
              return `${label}: ${value}`;
            }
          }
        }
      }
    },
  };

  function activateTooltip(datasetIndex, pointIndex) {
    const chartInstance = chartRef.current;
  
    if (!chartInstance) return;

    const pointMeta = chartInstance.getDatasetMeta(datasetIndex).data[pointIndex];
    if (!pointMeta) return;

    chartInstance.data.datasets.forEach((dataset, index) => {
      if (index === (datasetIndex)) {
        dataset.borderWidth = 2;
      } else {
        if (!dataset.originalBorderColor) {
          dataset.originalBorderColor = dataset.borderColor;
          dataset.originalBackgroundColor = dataset.backgroundColor;
        }
        dataset.borderColor = '#D7DBDD ';
        dataset.backgroundColor = '#F2F3F4 ';  
      }
    });  
    chartInstance.tooltip.setActiveElements([{ datasetIndex, index: pointIndex}], {
      x: pointMeta.x, 
      y: pointMeta.y
    });

    chartInstance.update();
  }

  function closeTooltip() {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;

    chartInstance.tooltip.setActiveElements([], {});
    chartInstance.update();
  }

  useEffect(() => {
    setIsLoading(props.isloading);
  }, [props.isloading]);


  return (
    <>
      {isLoading ? (
        <LoadingGraph />
      ) : (
        <div className="graph">
          <div >
      <Chart  key={chartKey}
            type="line"
            data={data}
            options={options}
            ref={chartRef}/>
      </div>
        </div>
      )}
    </>
  );
}
