import React, { useEffect,useState } from 'react';
import {useCountries} from '../../../hooks/CIC/useCountries'
export default function CICCountriesDropdown({ onCountrySelect,country }) {
    const { Countries, cicerror } = useCountries();

    const [selectedCountry, setSelectedCountry] = useState('');
    useEffect(() => { 
    if(typeof(Countries[0]) !=='undefined' && selectedCountry == ''){
      
        setSelectedCountry(Countries[0].Name);    
        onCountrySelect(Countries[0].Name);     
    }
    },[Countries,selectedCountry,country])

    const handleChange = (e) => {
      const newCountry = e.target.value;
      setSelectedCountry(newCountry);
      onCountrySelect(newCountry); // Call the callback function with the selected time
    };

    return (
      <>
        <select className='select' value={selectedCountry} onChange={handleChange}>
          <option value="">Select Country</option>
          {Countries.map((item, index) => (
            <option key={item.Name} value={item.Name}>
              {item.Name}
            </option>
          ))}
        </select>   
      </>
    );
  }
  