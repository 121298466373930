import React, { useEffect, useState } from 'react';
import '../../../pages/ciccomparison/ciccomparison.css';
import LoadingTbl from '../../loading/LoadingTbl';

export default function CICComparisonTable(props) {
  const { filteredComparisonList, denominationtype, graphLabels, graphColors } = props;
  const [isloading,setisloading]=useState(false);
  const multiplierValue = filteredComparisonList.length > 0 ? filteredComparisonList[0].Multiplier : "";
  const cleanDigits = (number) => {
    return Number(number) < 0
      ? Number(number).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      : Number(number).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };
  const allColumnNames = filteredComparisonList.reduce((columns, item) => {
    Object.keys(item).forEach(columnName => columns.add(columnName));
    return columns;
  }, new Set());
  const handleMouseEnter = (columnIndex, rowIndex) => {
    const updateIsHovered = props.updateishovered || (() => {});
    updateIsHovered(true);
    props.updateGraphIndex(rowIndex, columnIndex);
  };

  const handleMouseLeave = () => {
    const updateIsHovered = props.updateishovered || (() => {});
    updateIsHovered(false);
  };
   useEffect(()=>{
    if(!props.isloading){
      setisloading(false)
    } else {
      setisloading(true)
    }
   },[props, props.graphLabels]);
  return (
    <>
      {filteredComparisonList.length === 0 || isloading === true ? (
        <LoadingTbl />
      ) : (
        <table className='CIC-Tabledata table table-effect'>
          <label className='denom-title'>
            {denominationtype} <span className='multiplier'>Total Volume</span>
          </label>
          <thead>
            <tr>
              <th>Year</th>
              {graphLabels.map((label, index) => (
                <th 
                  key={index} 
                  style={{ backgroundColor: graphColors[index] }}
                >
                  {label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {/* New Multiplier Row */}
            <tr>
              <td>Multiplier</td>
              {Array.from(allColumnNames).filter(column => column !== "Year" && column !== "Multiplier"&& column !== "Type").map((column) => (
                <td key={`multiplier-${column}`}>
                  {props.multiplierlist.find(item => item.CountryName === column&& item.Type == denominationtype)?.Multiplier || 'N/A'}
                </td>
              ))}
            </tr>
            {/* Existing Rows */}

            {filteredComparisonList.filter(item => item.Type === denominationtype).map((item, columnIndex) => (
              <tr key={item.Year}>
                <td>{item.Year}</td>
                {Array.from(allColumnNames).filter(column => column !== "Year" && column !== "Multiplier"&& column !== "Type").map((column, rowIndex) => (
                  <td 
                  key={`${item.Year}-${column}-${rowIndex}`} 

                    onMouseLeave={handleMouseLeave} 
                    onMouseEnter={() => handleMouseEnter(columnIndex, rowIndex)}
                  >
                    {cleanDigits(item[column])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}