import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import './Popover.css'
import Info from '../../assets/img/icons/info-alt.png';
import {useToolTipPost} from '../../hooks/CIC/useToolTipPost';
import DOMPurify from 'dompurify';
const Popover = ({ fragment }) => {
  const [popoverVisible, setPopoverVisible] = useState(false);
  const popoverRef = useRef();
  const {post,error} = useToolTipPost(fragment);

  const togglePopover = () => {
    setPopoverVisible(!popoverVisible);
  };

  const closePopover = () => {
    setPopoverVisible(false);
  };

  const handleOutsideClick = (e) => {
    if (popoverRef.current && !popoverRef.current.contains(e.target)) {
      closePopover();
    }
  };
  function removeHtmlTags(str) {
    if (str === null || str === "") return false;
    else str = str.toString();
    
    // Regular expression to identify HTML tags in 
    // the input string. Replacing the identified 
    // HTML tag with a null string.
    return str.replace(/(<([^>]+)>)/ig, '');
  }
  React.useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);
  function SafeHtml({ htmlString }) {
    // This creates an object with a `__html` property that React can use to set inner HTML.
    // We use DOMPurify to sanitize the HTML and prevent XSS attacks.
    const sanitizedHtml = DOMPurify.sanitize(htmlString);
  
    return <div dangerouslySetInnerHTML={{ __html: sanitizedHtml }} />;
  }
  return (
    <div className="popover" ref={popoverRef}>
      <button className="popover-btn" onClick={togglePopover}>
        <img src={Info} alt="info" />
      </button>
      {popoverVisible && (
        <div className="popover-content">
          <div className="popover-header">{post.header}</div>
          <div className="popover-body"dangerouslySetInnerHTML={{ __html: post.content}}></div>
        </div>
      )}
    </div>
  );
};

export default Popover;
