import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';

function YearRangePicker({onStartYearSelect,onEndYearSelect,country,iscountrychanged,StartYear,EndYear,Years,handleischangedcountry}) {
  const [startYear, setStartYear] = useState('');
  const [endYear, setEndYear] = useState('');
  const [startYearchanged, setStartYearChanged] = useState(false);
  const [endYearchanged, setEndYearChanged] = useState(false);
  useEffect(()=>{

 if( Years.length > 0){
    if(startYear !== StartYear){
      setStartYear(Years[0].Year);
      onStartYearSelect(startYear)
    }
    if(endYear !== EndYear){
      setEndYear(Years[Years.length -1].Year);
      onEndYearSelect(endYear) ;
    } 
    if(iscountrychanged){
      setStartYearChanged(false)
      setEndYearChanged(false)
    }
    if(startYearchanged === false){
        setStartYear(Years[0].Year);
        onStartYearSelect(startYear)
    }

    if(endYearchanged === false){
      setEndYear(Years[Years.length -1].Year);
      onEndYearSelect(endYear)  
    }
 }
  },[Years,StartYear,EndYear,country]);  
  
    const handleStartYearChange = (event) => {
      const newStartYear = parseInt(event.target.value);
      if (newStartYear <= EndYear) {
        setStartYearChanged(true);
        setStartYear(newStartYear);
        onStartYearSelect(newStartYear);
      }
    };
  
    const handleEndYearChange = (event) => {
      const newEndYear = parseInt(event.target.value);
      if (newEndYear >= StartYear) {
       setEndYearChanged(true)
       setEndYear(newEndYear);
       onEndYearSelect(newEndYear);  
       
      }
    };
    var startYearOptions = Years.filter((item) => item.Year < endYear);
    startYearOptions = startYearOptions.slice(0, startYearOptions.length - 1);
  
    var endYearOptions = Years.filter((item) => item.Year > startYear);
    endYearOptions = endYearOptions.slice(1);

    return (
      <div className='select-box select-year-range'>
        <p>
          <span>Selected Year Range:</span> {startYear} <span>to</span> {endYear}
        </p>
        <div className='select-group'>
          <label htmlFor="startYearPicker">Select Start Year:</label>
          <select
            className='select'
            id="startYearPicker"
            onChange={handleStartYearChange}
            value={startYear}
          >
            {startYearOptions.map((year) => (
              <option key={year.Year} value={year.Year}>
                {year.Year}
              </option>
            ))}
          </select>
        </div>
        <div className='select-group'>
          <label htmlFor="endYearPicker">Select End Year:</label>
          <select
            className='select'
            id="endYearPicker"
            onChange={handleEndYearChange}
            value={endYear}
          >
            {endYearOptions.map((year) => (
              <option key={year.Year} value={year.Year}>
                {year.Year}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }
  
export default YearRangePicker;