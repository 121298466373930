import { useState, useEffect } from 'react';
import Axios from 'axios';
import configdata from '../../appsettings.json';
export const useCountriesBySubRegion = (initialList) => {
    const subregionsString = initialList.toString();
    const [CICCountries, setCICCountries] = useState([]);
    const [error, setError] = useState(null);
    const apiURL = configdata.apiServer_Url +"GetCountriesBySubRegions";
   // console.log(subregionsString)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.post(apiURL, { SubRegions: subregionsString });
              setCICCountries(response.data);
            } catch (error) {
                setError(error);
            }
        };
        if(subregionsString !="")
        {
            fetchData(); 
        }

    }, [initialList]);

    return { CICCountries,error };
};
