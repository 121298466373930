import './Overview.css'
import Pattern from '../../assets/img/pattern.svg'
import Bar from '../../components/Bar'
import Earth from '../../assets/img/gif/earth.gif'
import Graph from '../../assets/img/gif/graph.gif'
import LoadingEllipsis from '../../components/loading/LoadingEllipsis'
import LoadingSpinner from '../../components/loading/LoadingSpinner'
import LoadingGraph from '../../components/loading/LoadingGraph'
// import LoadingEllipsis from '../../components/loading/LoadingEllipsis'

export default function  Overview() {
  
  return (
    <>
      <div className='bar'>
        <Bar />
      </div>
      <div className='main'>
        <img src={Pattern} alt="CiC" />
      </div>
      <div className='xlong map-wrap'>
          <div className='map-data'>
          <LoadingEllipsis />
          <p className='center'>graph</p>
        </div>
        <div className='map'>
          map
          <div id='map_container'>
            <img src={Earth} alt="earth-img" className='earth-img'/>
          </div>
        </div>
      </div>
      <div className='long'>
        <p className='center'>graph</p>
        <LoadingGraph />
      </div>
      <div className='gif-box'>
        <img src={Graph} alt="graph-img" className='graph-img'/>
      </div>
      <div>
        <p className='center'>data</p>
        <LoadingSpinner />
      </div>
    </>
  )
}
