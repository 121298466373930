import './LoadingGraph.css'

export default function LoadingGraph() {
  return (
    <div className='loading-graph'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  )
}
