import { useState, useEffect } from 'react';
import axios from 'axios';
import configdata from '../../appsettings.json';
export const useCICGetMarketDataByCountryYearlyChange = (country) => {
  const [error, setError] = useState(null);
  const [cicmarketDataYearlyChangeList, setcicmarketDataYearlyChangeList] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        var url = configdata.apiServer_Url +"GetCICMarketDataByCountryYearlyChange";
        const data = { country: country };
        const response = await axios.post(
          url,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );

        setcicmarketDataYearlyChangeList(response.data);  

        if(response.data.length === 0){
            setError("Country Info No Data");
        }
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    if(country !==''){
            fetchData(); // Call the fetchData function on component mount or when 'country' changes.
    }
  }, [country]);

  return { cicmarketDataYearlyChangeList, error };
};