import { useState, useEffect } from 'react';
import Axios from 'axios';
import configdata from '../../appsettings.json';
export function useCICCountriesByCountriesYearlyChange (initialList) {
    const [countriesYearlyChange, setCountriesYearlyChange] = useState([])
    const [error, setError] = useState(null)
    const countries = initialList.toString();
    const apiURL = configdata.apiServer_Url +"GetCICCountriesYearlyChangeByCountries";
    useEffect(() => {
        const fetchNotesData = async () => {
            try{
                const response = await Axios.post(apiURL, {denominationtype: "Notes", countries: countries});
                setCountriesYearlyChange(response.data);
            } catch(error) {
                setError(error)
            }
        };     
        if(countries != ""){
            fetchNotesData();
        }

    },[initialList]);

    return {countriesYearlyChange,error};
}