import '../../../pages/ciccomparison/ciccomparison.css';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import configdata from '../../../appsettings.json';
import LoadingGraph from '../../loading/LoadingGraph';

export default function CICDenominationGraph ( props ) {
  const [isLoading, setIsLoading] = useState(false);
  const [chartKey, setChartKey] = useState(0); // State to track the chart key
  const chartRef = useRef(null);
  const containsOnlyZerosAfterDecimal = value => /^\d+\.(0+)$/.test(value);
  const convertToIntegerIfNoDecimal = value => containsOnlyZerosAfterDecimal(value) ? parseInt(value) : value;
  const allYears = props.filteredComparisonList.map(item => item.Year);
  const yearlyChangeslist = props.filteredComparisonList.reduce((acc, item) => {
     const country = convertToIntegerIfNoDecimal(item.Country);
     if (!acc[country]) {
        acc[country] = allYears.map(() => 0);
     }
     const yearIndex = item.Year-allYears[0];
     acc[country][yearIndex] = item['YearlyPercentageChange'] ?? 0;
     return acc;
  }, {});

  useEffect(()=>{
    if(props.ishovered=== true){
        activateTooltip(props.columnIndex, props.rowIndex)
    }
    else{
      closeTooltip();
    }

  }, [props.ishovered, props.rowIndex, props.columnIndex, yearlyChangeslist]);
  
  const uniqueYears = [...new Set(props.filteredComparisonList.map(item => item.Year))]
    .sort((a, b) => a - b);

  const sortedlist = Object.keys(yearlyChangeslist).sort((a, b) => parseFloat(a) - parseFloat(b));
  const datasets = sortedlist.map(country => {
    const yearlyChanges = yearlyChangeslist[country];
    return {
      label: country,
      data: yearlyChanges,
      fill: false,
      borderColor: configdata.GraphBackGroundColors[sortedlist.indexOf(country) % configdata.GraphBackGroundColors.length],
      borderWidth: 1.2,
      pointRadius: 2,
      pointBackgroundColor: configdata.GraphPointColors[sortedlist.indexOf(country) % configdata.GraphBackGroundColors.length],
      backgroundColor: configdata.GraphPointColors[sortedlist.indexOf(country) % configdata.GraphPointColors.length],
      tension: 0.3,
    };
  });
  const [selectedButton, setSelectedButton] = useState(null);
 
  function activateTooltip(datasetIndex, pointIndex) {
    const chartInstance = chartRef.current;
  
    if (!chartInstance) return;

    const pointMeta = chartInstance.getDatasetMeta(datasetIndex).data[pointIndex];
    if (!pointMeta) return;

    chartInstance.data.datasets.forEach((dataset, index) => {
      if (index === datasetIndex) {
        dataset.borderWidth = 2;
      } else {
        if (!dataset.originalBorderColor) {
          dataset.originalBorderColor = dataset.borderColor;
          dataset.originalBackgroundColor = dataset.backgroundColor;
        }
        dataset.borderColor = '#D7DBDD ';
        dataset.backgroundColor = '#F2F3F4 ';  
      }
    });  
    chartInstance.tooltip.setActiveElements([{ datasetIndex, index: pointIndex }], {
      x: pointMeta.x, 
      y: pointMeta.y
    });

    chartInstance.update();
  }

  function closeTooltip() {
    const chartInstance = chartRef.current;
    if (!chartInstance) return;

    chartInstance.tooltip.setActiveElements([], {});
    chartInstance.update();
  }

  const data = {
    labels: uniqueYears,
    datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year',
        },
      },
      y: {
        title: {
          display: true,
          text: 'Yearly Change In %',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Yearly Change for ' + props.denominationtype + " Volume",
        font: {
          size: 16,
        },
      }, ticks: {
        callback: function(value, index) {
          // Hide the first label
          return index === 0 ? '' : value;
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let label = context.parsed.y !== null ? context.parsed.y + ' %' : '';
            const datasetIndex = context.datasetIndex;
            const dataIndex = context.dataIndex;
            if (datasetIndex !== undefined && dataIndex !== undefined) {
              const denomination = datasets[datasetIndex].label;
              label = ` ${denomination}: ${label}`;
            }
            return label;
          },
        },
      },
    },
  };
  const legendValues = useMemo(() => datasets.map(dataset => dataset.label), [datasets]);
  const backgroundColors = useMemo(() => datasets.map(dataset => dataset.borderColor), [datasets]);

  useEffect(() => {
    if (legendValues.length > 0 && backgroundColors.length > 0) {
      props.onGraphUpdate(legendValues, backgroundColors);
    }
  }, [legendValues, backgroundColors]);
  
  useEffect(() => {
    // Only when the loading is finished, resize the chart
    if (!props.isloading) {
      setIsLoading(false);
      // forceChartResize();
    } else {
      setIsLoading(true);
    }
  }, [props.isloading]); 
  return (
    <>
      {/* <button className='test-btn' onClick={forceChartResize}>Resize Graph</button> */}
      {isLoading === true ? (
        <LoadingGraph />
      ) : (
        <div className="graph">
          <Chart
            key={chartKey}
            type="line"
            data={data}
            options={options}
            ref={chartRef}
          />
        </div>
      )}
    </>
  );
}
