import React from 'react';
import { useGetCashInCirculationFilterByYear } from '../../../hooks/CIC/useCashInCirculationFilterByYear';
import LoadingTbl from '../../loading/LoadingTbl';

export default function CICTable(props) {
  // Helper function to format numbers with 3 decimal places and add commas
  const cleanDigits = (number) => {
    // Convert to a number, format with 3 decimal places, and add commas
    return Number(number).toFixed(3).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  };

  const addCommas = (number) => {
    // Convert to a number, remove decimals, and add commas
    return Number(number).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
  };

    // Replace with the desired country name
  const { CashInCirculationCountryByYear, error } = useGetCashInCirculationFilterByYear(
        props.country,
        props.denominationtype,
        props.startyear,
        props.endyear,
      );
  // multiplier label - NOTE the ***Multplier*** variable is misspelt 
  const multiplierValue = CashInCirculationCountryByYear.length > 0 ? CashInCirculationCountryByYear[0].Multplier : "";

  return (
    <>
    {CashInCirculationCountryByYear.length === 0 ? (
        <>
          <LoadingTbl />
        </>
      ) : (
        <table className='CIC-Tabledata table'>
          <label className='denom-title'>{props.denominationtype} <span className='multiplier'> in  {multiplierValue}</span></label>
          <thead>
            <tr>
              <th>Year</th>
              <th>Total Volume</th>
              <th>Total Value</th>
              <th>Volume Yearly Change</th>
              <th>Volume CAGR</th>
            </tr>
          </thead>
          <tbody>
            {/* <tr>
              <th>Year</th>
              <th>Total Volume</th>
              <th>Total Value</th>
              <th>Volume Yearly Change</th>
              <th>Volume CAGR</th>
            </tr>       */}
          {CashInCirculationCountryByYear.map((item, index) => (
              <tr key={item.Year}>
                <td>{item.Year}</td>
                <td>{item["Total Volume"] ? cleanDigits(item["Total Volume"]) : "-"}</td>
                <td>{item["Total Value"] ? addCommas(item["Total Value"]) : "-"}</td>
                <td>{item["Yearly Change"] !== null ? `${item["Yearly Change"]} %` : "-"}</td>
                <td>{item.CAGR !== null ? `${item.CAGR} %` : "-"}</td>
              </tr>
          ))}         
          </tbody>
        </table>
      )}
    </>
  );
}
