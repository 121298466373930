import { useState, useEffect } from 'react';
import Axios from 'axios';
import configdata from '../../appsettings.json';
export const useCICSubRegions = (initialRegionList) => {
   
    const regionsString = initialRegionList.toString();
    const [CICSubRegions, setCICSubRegions] = useState([]);
    const [error, setError] = useState(null);
    const apiURL = configdata.apiServer_Url +"GetCICSubRegions"; 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await Axios.post(apiURL, { Regions: regionsString });
                setCICSubRegions(response.data);
            } catch (error) {
                setError(error);
            }
        };
        if(initialRegionList != "" )
        {
        fetchData();
        }
    }, [initialRegionList]);

    return { CICSubRegions,error };
};
