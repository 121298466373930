import React, { useEffect, useState , useRef} from 'react';
import { useParams } from 'react-router-dom';
import CICCountriesDropdown from '../../components/CIC/CICOverView/CountriesDropDown';
import CICCountryInfo from '../../components/CIC/CICOverView/CICCountryInfo';
import CashInCirculation from '../../components/CIC/CICOverView/CashInCirculation';
import YearRangePicker from '../../components/CIC/CICOverView/YearRangePicker';
import Sidenav from '../../components/Sidenav';
import { useYears } from '../../hooks/CIC/useYears';
import './CiC.css';
import CICDenomination from '../../components/CIC/CICOverView/CICDenomination';
import Tabs from '../../components/Tabs';
import Popover from '../../components/ToolTips/Popover';
//IMAGES
import Arrow from '../../assets/img/icons/down-arrow.png'
//LOADING
import LoadingEllipsis from '../../components/loading/LoadingEllipsis';
export default function CiC(props) {
  const { id } = useParams();
  const childRef = useRef(null);
  const [fragment,setFragment] = useState("tcic-info");
  const [iscountrychanged, setiscountrychanged] = useState(false); 
   const [country, setCountry] = useState('');
  const { Years, cicerror } = useYears(country); 
  const [startyear, setStartYear] = useState('');
  const [endyear, setEndYear] = useState('');
  const [loading, setLoading] = useState(true); // Add a loading state
  //SHOW AND HIDE COUNTRY INFO
  const [isCramped, setIsCramped] = useState(true);
  const [isSelectBtnActive, setIsSelectBtnActive] = useState(false);
  // add 2nd cloick event to the flag
const  [bettontext,setbuttontext] = useState("Show Country Info");
  const handleCountrySelect = (selectedCountry) => {
    setCountry(selectedCountry);
    if (Years.length > 0) {
      if (startyear !== '') {
        setStartYear(Years[0].Year);
      }
      if (endyear !== '') {      
        setEndYear(Years[Years.length - 1].Year);
      }   
    }
     setiscountrychanged(true)
  };
  const handleTabs = (tab) => {
    setFragment("tcic-info");
    if(tab ==="TotalCashInCirculation"){
      setFragment("tcic-info");
    }
    else{
      setFragment("cic-denominations-info");
    }
    setActiveTab(tab);
  };

const handleiscountryopen =()=>{
  setIsCramped(!isCramped);
  setIsSelectBtnActive(!isSelectBtnActive);
}
  
  const handleStartYearSelect = (selectedYear) => {
    setStartYear(selectedYear);
  };

  const handleischangedcountry = (handleChange) => {
    setiscountrychanged(handleChange);
  };

  const handleEndYearSelect = (selectedYear) => {
    setEndYear(selectedYear);
  };

  useEffect(() => {
    if (Years.length > 0) {
     
      if(iscountrychanged === true){     
        setiscountrychanged(false)
      }
     
      setLoading(false); // Data is loaded, set loading to false
    }
     if(isCramped === false && isSelectBtnActive ===true){
      setbuttontext("Hide Country Info")
     }
     else{
      setbuttontext("Show Country Info")
     }
  }, [startyear,endyear,isCramped,isSelectBtnActive,country,Years]);
  useEffect(() => {
    if (Years.length > 0) {
        setStartYear(Years[0].Year);
        setEndYear(Years[Years.length - 1].Year);
    }  
}, [Years]);
  const [activeTab, setActiveTab] = useState('TotalCashInCirculation');


  return (
    <div className="dv-container cic-container">
      <Sidenav />
      <div className="dv-main">
        <div className="head">
          <div className="header">
            <h2>
              Welcome to the
              <span>DataVault</span>
            </h2>
            <h1>Cash in Circulation Dashboard</h1>
          </div>
        </div>
        <div className={`body ${isCramped ? 'cramped' : ''}`}>
          <div className="bar">
            <CICCountriesDropdown onCountrySelect={handleCountrySelect} />
            <YearRangePicker
              onStartYearSelect={handleStartYearSelect}
              onEndYearSelect={handleEndYearSelect}
              StartYear={startyear}
              EndYear={endyear}
              Years ={Years}
              country={country}
              iscountrychanged={iscountrychanged}
              handleischangedcountry ={handleischangedcountry}
            />
            <p
              id="showCountryInfo"
              className={`select select-btn ${
                isCramped ? 'active' : ''
              } ${isSelectBtnActive ? 'select-btn-active' : ''}`}
              onClick={() => {
                handleiscountryopen()
              }}
            >
              {bettontext}
              <img src={Arrow} alt="CiC" />
            </p>
          </div>
          {loading ? ( 
            <LoadingEllipsis />
          ) : country === '' ? (
            <p className="notice">Please select a country</p>
          ) : (
            <>
              <CICCountryInfo country={country} isCramped={isCramped} handleiscountryopen={handleiscountryopen} />
              <div className='tables-wrap'> 
                <Tabs setActiveTab={handleTabs} activeTab={activeTab} />
                <Popover 
                  header="Popover" 
                  content="The content for the popover goes here." 
                  fragment={fragment}
                />
                {activeTab === 'TotalCashInCirculation' ? (
                  <CashInCirculation
                    country={country}
                    years={Years}
                    startyear={startyear}
                    endyear={endyear}
                  />
                ) : (
                  <CICDenomination  country={country}
                  years={Years}
                  startyear={startyear}
                  endyear={endyear}
                  />

                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}