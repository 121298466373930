import {useEffect,useState} from 'react'
import "chart.js/auto"
import { Chart } from "react-chartjs-2"
import {useGetCashInCirculationFilterByYear} from '../../../hooks/CIC/useCashInCirculationFilterByYear'
import configdata from '../../../appsettings.json';
import LoadingGraph from '../../loading/LoadingGraph';
export default function CiCGraphs(props) {
  const [trendlineData, settrendlineData] = useState([]);
  const { CashInCirculationCountryByYear, error } = useGetCashInCirculationFilterByYear(
    props.country,
    props.denominationtype,
    props.startyear,
    props.endyear,
  );
  //var lastindex = (CashInCirculationCountryByYear.length-1)
  // Replace with the desired country name
  const yearList = CashInCirculationCountryByYear.map(item => item.Year);
  const yearchangeList = CashInCirculationCountryByYear.map(item => item["Yearly Change"] !== null ? item["Yearly Change"] : 0);
  //const cagrList = CashInCirculationCountry.map(item => item.CAGR)
  
  // Use .slice(1) to skip the first value in yearList
  const modifiedYearList = yearList;
  const modifiedYearChangeList = yearchangeList;

  
  // Function to calculate the trendline data
  function loopXTimes(cagr,x) {
    var trendlist =[];
    for (let i = 0; i < x; i++) {
     trendlist =[...trendlist,cagr];
      // Your code for each iteration goes here
    }
    return trendlist
  }
 
  useEffect(()=>{

 if(CashInCirculationCountryByYear.length >0){
  
 settrendlineData(loopXTimes(CashInCirculationCountryByYear[CashInCirculationCountryByYear.length-1].CAGR,CashInCirculationCountryByYear.length))
  }
},[CashInCirculationCountryByYear]);
  const data = {
    labels: modifiedYearList,
    datasets: [
      {  key:'data-1',
        label: props.denominationtype,
        data: modifiedYearChangeList,
        fill: true, // Fill the area under the line
        backgroundColor: 'rgba(89, 40, 229, 0.2)', // Background color under the line
        borderColor: configdata.GraphBackGroundColors[0],
        borderWidth: 1, // Line width
        pointRadius: 2, // Point size
        pointBackgroundColor: configdata.GraphBackGroundColors[0], // Point color
        tension: .3,
      },
      {
        key:'Trendline-1',
        label: 'CAGR',
        data: trendlineData,
        fill: false, // No fill under the trendline
        borderColor: configdata.CAGRTrendLineColor, // Adjust the color as needed
        borderWidth: 1,
        pointRadius: 0, // No points on the trendline
        tension: 0, // Set tension to 0 for a straight line
        borderDash: [5, 5], // Optional: Add dashes to the line
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Year', // X-axis title
        },
      },
      y: {
        title: {
          display: true,
          text: 'Yearly Change In %', // Y-axis title
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      title: {
        display: true,
        text: 'Yearly Change for ' + props.denominationtype + " Volume", // Title for your chart
        font: {
          size: 16, // Adjust the font size as needed
        },
      },tooltip: {
        callbacks: {
          label: (context: any) => {
            let label = "";
            if (context.parsed.y) {
              label = context.parsed.y + "%"
            }
            return label;
          }
        }
      }
    },
    };
  

  return (
    <>
    {CashInCirculationCountryByYear.length === 0 ? (
      <LoadingGraph />
    ) : (
    <div className='graph'>
      <Chart
        type="line"
        data={data}
        options={options}
      />
    </div>
    )}
    </>
  )
}
