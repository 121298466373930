import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import React, { useEffect, useState } from 'react';

export const MultiselectDropdownList = ({ onSelect, list,listname,islistchanged,setislistchanged }) => {
    const animatedComponents = makeAnimated();
    const [selectedItems, setSelectedItems] = useState([]);
    const [modifiedlist, setmodifiedlist] = useState([]);
    const [firstloaded, setfirstloaded] = useState(true);
    // Update modifiedlist and set default selected item when list changes
    useEffect(() => {
        if (list.length > 0) {
            const allOption = { value: 'All ' + listname, label: 'All ' + listname };
            const formattedList = [allOption, ...list.map(item => ({ value: item, label: item }))];
            setmodifiedlist(formattedList);
    if (selectedItems.length === 0 && formattedList.length > 0) {
            // Check if listname is 'countries' and select only the first 5 items
            if (listname === 'Countries') {
                const defaultSelected = [formattedList[0]];
                const firstFiveItems = formattedList.slice(1, 6); // Adjust slice to get first 5 items
                setSelectedItems(firstFiveItems);
                onSelect(firstFiveItems.map(item => ({ value: item.value, label: item.label })));
                setfirstloaded(false)
            } else {
                // Handle the default case for other listnames
                const defaultSelected = [formattedList[0]];
                setSelectedItems(defaultSelected);
                onSelect(list.map(item => ({ value: item, label: item })));
            }
             
        }
        if(islistchanged== true){
                        
           if (listname === 'Countries') { 
                      
            setSelectedItems(formattedList.slice(1, 6));
            onSelect(formattedList.slice(1, 6));  
           
        }
        else{
            if(selectedItems.length < 2)
            {
            const updatedSelectedItems = selectedItems.filter(selectedItem =>
                list.includes(selectedItem.value)
            );    
            if (updatedSelectedItems.length !== selectedItems.length) {
                setSelectedItems(updatedSelectedItems);
                onSelect(updatedSelectedItems);      
               
            } 
        }
    }
             setislistchanged(false);       
        }
    }
    }, [list, selectedItems.length,islistchanged]);
    useEffect(() => {
        if (firstloaded) {
            // Set initial default selection here
            const initialDefaultSelection = [{ value: 'Loading...', label: 'Loading...' }];
            setSelectedItems(initialDefaultSelection);
        }
    }, [firstloaded]);

    const handleEvent = (selectedOptions) => { 
        const allOption = { value: 'All ' + listname, label: 'All ' + listname };
        // Check if selectedOptions is defined and not empty
        if (!selectedOptions || selectedOptions.length === 0) {
            // Select the first item from the list if no item is selected
            const firstItemSelection = modifiedlist.length > 1 ? [modifiedlist[1]] : [];
            setSelectedItems(firstItemSelection);
            onSelect(firstItemSelection);

            return; // Exit the function after handling this case
        }
    
        const isAllCurrentlySelected = selectedItems.some(option => option.value === allOption.value);
        const isAnotherOptionSelected = selectedOptions.some(option => option.value !== allOption.value);
    
        if (isAllCurrentlySelected && isAnotherOptionSelected) {
            const newSelection = selectedOptions.filter(option => option.value !== allOption.value);
            setSelectedItems(newSelection);
            onSelect(newSelection); 
        } else {
            const isAllSelected = selectedOptions.some(option => option.value === allOption.value);
            if (isAllSelected) {
                setSelectedItems([allOption]);
                onSelect(modifiedlist);
            } else {              
                setSelectedItems(selectedOptions);
                onSelect(selectedOptions);
            }
        }
     
    };

    return (
        <div className="MultiSelect">
             <label htmlFor="">Select {listname}</label>
            <Select
                closeMenuOnSelect={false}
                components={animatedComponents}
                value={selectedItems}
                isMulti
                isSearchable
                options={modifiedlist}
                onChange={handleEvent}
            />
        </div>
    );
};
