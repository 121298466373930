// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.burger-nav {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer; 
    z-index: 50;
    display: none;
  }
  
  .mobile-nav {
    display: none; 
    background: rgba(0, 0, 0, 0.95);
    position: absolute;
    inset: 0;
    z-index: 10;
    transition: transform 0.3s ease-in-out; 
    transform: translateX(100%); 
  }
  
  .mobile-nav.open {
    display: block; 
    transform: translateX(0); 
  }
  
  .mobile-nav .nav-wrap {
    display: flex;
    flex-direction: column;
  }
  
  .mobile-nav .nav-wrap a {
    flex: 1 1;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    margin: 1rem 0;
  }
  
  .mobile-nav .logo {
    margin: 2rem auto 3rem;
    text-align: center;
  }
  
  .mobile-nav .logo img {
    height: 80px;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/mobile/Mobile.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,eAAe;IACf,WAAW;IACX,aAAa;EACf;;EAEA;IACE,aAAa;IACb,+BAA+B;IAC/B,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,sCAAsC;IACtC,2BAA2B;EAC7B;;EAEA;IACE,cAAc;IACd,wBAAwB;EAC1B;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,SAAO;IACP,WAAW;IACX,eAAe;IACf,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,YAAY;EACd","sourcesContent":[".burger-nav {\n    position: absolute;\n    top: 1rem;\n    right: 1rem;\n    cursor: pointer; \n    z-index: 50;\n    display: none;\n  }\n  \n  .mobile-nav {\n    display: none; \n    background: rgba(0, 0, 0, 0.95);\n    position: absolute;\n    inset: 0;\n    z-index: 10;\n    transition: transform 0.3s ease-in-out; \n    transform: translateX(100%); \n  }\n  \n  .mobile-nav.open {\n    display: block; \n    transform: translateX(0); \n  }\n  \n  .mobile-nav .nav-wrap {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .mobile-nav .nav-wrap a {\n    flex: 1;\n    color: #fff;\n    font-size: 2rem;\n    text-align: center;\n    margin: 1rem 0;\n  }\n  \n  .mobile-nav .logo {\n    margin: 2rem auto 3rem;\n    text-align: center;\n  }\n  \n  .mobile-nav .logo img {\n    height: 80px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
