import React from 'react';
import {useGetCountryInfo} from '../../../hooks/CIC/useCountryInfo'
  
const CICCountryInfo = (props) => {
  const addCommas = (number) => {
    // Convert to a number, remove decimals, and add commas
    return Number(number).toFixed(0).replace(/\d(?=(\d{3})+$)/g, '$&,');
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //this section of code calls and stores the data for Country Info and CashInCirculation plugins

   const { countryInfo, countryerror } = useGetCountryInfo(props.country);
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // Handle the click event on the .country-img element
    const handleCountryImgClick = () => {
      // Toggle the 'isCramped' state by calling the function from props
      props.handleiscountryopen();
    };

    return (
          <>
            {typeof(countryInfo) == 'undefined' ? (
            <p>Please select correct  country</p>
         ) : (
          <>
            <div className="block country-info">
              <div className="box">
                <div className='country-box'>
                  <h1 id="CountryName">{countryInfo.CountryName}</h1>
                  <h2 id="Region-SubRegion">{countryInfo.Region +", "+ countryInfo.SubRegion}</h2>
                  <div className='country-img' onClick={handleCountryImgClick}>
                    <img src={countryInfo.FlagUrl} alt="country" />
                  </div>
                </div>
                <div>
                  <h3>Issuing Authority: </h3>
                  <h4 id="IssuingAuthority">{countryInfo.IssuingAuthority}</h4>
                </div>
                <div>
                  <h3>Established In: </h3>
                  <h4 id="EstablishedIn">{countryInfo.Established}</h4>
                </div>
                <div>
                  <h3>Currency: </h3>
                  <h4 id="Currency">{countryInfo.Currency}</h4>
                </div>
                <div>
                  <h3>Currency Code: </h3>
                  <h4 id="CurrencyCode">{countryInfo.CurrencyCode}</h4>
                </div>
              </div>
              <div className="box">
                <h2>Latest figures for year<span>2021</span></h2>
                <div>
                  <h3>Population: </h3>
                  <h4 id="Population">{addCommas(countryInfo.Population)}</h4>
                </div>
                <div>
                  <h3>GDP: </h3>
                  <h4 id="GDP">{addCommas(countryInfo.GDP)}</h4>
                </div>
                <div>
                  <h3>GDP/Capita: </h3>
                  <h4 id="GDP-Capita">{addCommas(countryInfo.GDPCapita)}</h4>
                </div>
                <div>
                  <h3>CPI: </h3>
                  <h4 id="CPI">{countryInfo.CPI} %</h4>
                </div>
              </div>
            
            </div></>)}
          </>
)
    }
export default CICCountryInfo; 