import { useState, useEffect } from 'react';
import axios from 'axios';
import configdata from '../../appsettings.json';
export const useGetCountryInfo = (country) => {
  const [error, setError] = useState(null);
  const [countryInfo, setCountryInfo] = useState({});
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        var url = configdata.apiServer_Url +"CountryInfo";
        const data = { Country: country };
        const response = await axios.post(
          url,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );
        setCountryInfo(response.data[0]);  
        if(response.data.length === 0){
            setError("Country Info No Data");
        }
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData(); // Call the fetchData function on component mount or when 'country' changes.
  }, [country]);

  return { countryInfo, error };
};