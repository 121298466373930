import React, { useEffect, useState } from 'react';
import Sidenav from '../../components/Sidenav';
import CICComparisonFilters from '../../components/CIC/CICComparison/CICComparisonFilters';
import CICComparisonGraph from '../../components/CIC/CICComparison/CICComparisonGraph';
import CICComparisonTable from '../../components/CIC/CICComparison/CICComparisonTable';
import './ciccomparison.css';
import Popover from '../../components/ToolTips/Popover';

export default function CICComparison(props) {
  const [FilteredComparisonTableList, setFilteredComparisonTableList] = useState([]);
  const [FilteredComparisonGraphList, setFilteredComparisonGraphList] = useState([]);
  const [MultiplerList, setMultiplerList] = useState([]);
  const [notesGraphLabels, setNotesGraphLabels] = useState([]);
  const [coinsGraphLabels, setCoinsGraphLabels] = useState([]);
  const [notesGraphColors, setNotesGraphColors] = useState([]);
  const [coinsGraphColors, setCoinsGraphColors] = useState([]);
  const [rowIndex,setRowIndex]= useState();
  const [columnIndex,setColumnIndex]= useState();
  const [isCoinsHovered,setisCoinsHovered]= useState(false);
  const [isNoteshovered,setIsNotesHovered]= useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fragment] = useState("cic-comparison-info");

  const handleLoadingChange = (loadingStatus) => {
      setIsLoading(loadingStatus);
  };
  const handlefilteredComparisonList = (comparisontablelist,comparisongraphlist,multiplierlist) => {
    setFilteredComparisonTableList(comparisontablelist);
    setFilteredComparisonGraphList(comparisongraphlist);
    setMultiplerList(multiplierlist)
    setIsLoading(false);
  };
  useEffect(()=>{

  },[isLoading])
  const updateNotesGraphIndex=(columnindex,rowindex)=>{
    setRowIndex(rowindex);
    setColumnIndex(columnindex);
  }

  const updateNotesIsHovered=(value)=>{
    setIsNotesHovered(value)   
  }

  const updateCoinsGraphIndex=(columnindex,rowindex)=>{
    setRowIndex(rowindex);
    setColumnIndex(columnindex);
  }

  const updateCoinsIsHovered=(value)=>{
    setisCoinsHovered(value)
  
  }

  const handleNotesGraphUpdate = (labels, colors) => {
    if (!arraysEqual(notesGraphLabels, labels)) {
      setNotesGraphLabels(labels);
    }
    if (!arraysEqual(notesGraphColors, colors)) {
      setNotesGraphColors(colors);
    }
  };

  const handleCoinsGraphUpdate = (labels, colors) => {
    if (!arraysEqual(coinsGraphLabels, labels)) {
      setCoinsGraphLabels(labels);
    }
    if (!arraysEqual(coinsGraphColors, colors)) {
      setCoinsGraphColors(colors);
    }
  };
  const arraysEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (let i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  return (
    <div className="dv-container cic-container comparison-container">
      <Sidenav/>
      <div className="dv-main">
        <div className="head">
          <div className="header">
            <h2>
              Welcome to the
              <span>DataVault</span>
            </h2>
            <h1>Total Cash in Circulation Comparison View</h1>
          </div>
        </div>
        <div className="body"> 
          <div className="bar">
            <CICComparisonFilters 
              onLoadingChange ={handleLoadingChange} 
              onFilterUpdate={handlefilteredComparisonList}
            />
          </div>
          <div className='comp-wrap'>
            <Popover 
              header="Popover" 
              content="The content for the popover goes here." 
              fragment={fragment}
            />
            <div>
              <CICComparisonTable 
                isloading={isLoading}
                multiplierlist = {MultiplerList}
                denominationtype={"Notes"}
                filteredComparisonList={FilteredComparisonTableList.filter(item => item.Type === "Notes")}
                graphLabels={notesGraphLabels} 
                graphColors={notesGraphColors} 
                updateGraphIndex ={updateNotesGraphIndex}
                updateishovered={updateNotesIsHovered}
              />
              <CICComparisonGraph 
                isloading={isLoading}
                onGraphUpdate={handleNotesGraphUpdate}
                rowIndex={rowIndex} 
                denominationtype={"Notes"}
                columnIndex={columnIndex} 
                ishovered={isNoteshovered} 
                filteredComparisonList={FilteredComparisonGraphList.filter(item => item.Type === "Notes")}
              />
            </div>
            <div>
              <CICComparisonTable 
                isloading={isLoading}
                multiplierlist = {MultiplerList}
                denominationtype={"Coins"}
                filteredComparisonList={FilteredComparisonTableList.filter(item => item.Type === "Coins")}
                graphLabels={coinsGraphLabels} 
                graphColors={coinsGraphColors} 
                updateGraphIndex ={updateCoinsGraphIndex}
                updateishovered={updateCoinsIsHovered}
              />
              <CICComparisonGraph 
                isloading={isLoading}
                onGraphUpdate={handleCoinsGraphUpdate}
                rowIndex={rowIndex} 
                columnIndex={columnIndex} 
                ishovered={isCoinsHovered} 
                denominationtype={"Coins"}
                filteredComparisonList={FilteredComparisonGraphList.filter(item => item.Type === "Coins")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}