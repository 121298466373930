import './assets/css/reset.css';
import './App.css';
import './assets/css/theme.css';

import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Topnav from './components/Topnav';
import Datavault from './pages/datavault/Datavault';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import CICComparison from './pages/ciccomparison/ciccomparison';
import CICMarketData from './pages/cicmarketdata/cicmarketdata';
import CiC from './pages/cic/CiC';
import MobileNav from './components/mobile/MobileNav';
//import { useDefaultSettings } from './hooks/useDefaultSettings';

function App() {
  //c//onst { error } = useDefaultSettings();

  return (
    <>
      <BrowserRouter>
        <div className="App">
          <Topnav />
          <MobileNav />
          <Routes>
            <Route path="/datavault" element={<Datavault />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/cic/:id" element={<CiC />} />
            <Route path="/cic/ciccomparison" element={<CICComparison />} />
            <Route path="/cic/cicmarketdata" element={<CICMarketData />} />
            <Route path="*" element={<Navigate to="/datavault" />} />
          </Routes>
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;