import { useState, useEffect } from 'react';
import axios from 'axios';
import configdata from '../../appsettings.json';

export const useCICCountryDenominationYearlyChange = (country,type,startyear,endyear) => {
  const [error, setError] = useState(null);
  const [CICCountryDenominationYearlyChange, setCICCountryDenominationYearlyChange] = useState([]);

  useEffect(() => {  

    const fetchData = async () => {
      try {
        const data = { Country: country, Type: type,startyear:startyear,endyear:endyear};   
        var url = configdata.apiServer_Url +"GetCICCountryDenominationsYearlyChange";
        const response = await axios.post(
          url,
          JSON.stringify(data),
          {
            headers: {
              'Content-Type': 'application/json; charset=utf-8',
            },
          }
        );

        setCICCountryDenominationYearlyChange(response.data);
      } catch (error) {
        setError(error);
        console.error(error);
      }
    };

    fetchData(); 
  }, [country,type,startyear, endyear]);

  return { CICCountryDenominationYearlyChange, error };
};
