import { NavLink } from 'react-router-dom'
import './Sidenav.css'
// images
import Logo from '../assets/img/icon.png'
import Home from '../assets/img/icons/home.png'
import CiC from '../assets/img/icons/cic.png'
import Book from '../assets/img/icons/book.png'
import Light from '../assets/img/icons/light.png'
import Settings from '../assets/img/icons/settings.png'
import Logout from '../assets/img/icons/logout.png'
import User from '../assets/img/icons/user.png'


export default function Sidenav() {
  return (
      <div className='sidenav'>
          <div className='logo'><img src={Logo} alt="logo" /></div>
          <div className='nav'>
            <ul>
              <li>
                <NavLink exact to="/datavault" activeClassName='active'><img src={Home} alt="home" /></NavLink>
                <div className='popout'>
                  <p>Overview</p>
                </div>
              </li>
              <li>
                <NavLink exact to="/cic/default" activeClassName='active'><img src={CiC} alt="CiC" /></NavLink>
                <div className='popout'>
                  <NavLink exact to="/cic/default" className='popout-link'>Cash in Circulation</NavLink>
                  <NavLink exact to="/cic/ciccomparison" className='popout-link'>Comparison View</NavLink>
                  <NavLink exact to="/cic/cicmarketdata" className='popout-link'>CIC Market Data</NavLink>
                </div>  
              </li>
              <li className='disabled'>
                <NavLink exact to="/" activeClassName='active'><img src={Book} alt="book" /></NavLink>
                <div className='popout'>
                  <p>Book</p>
                </div>
              </li>
              <li className='disabled'>
                <NavLink exact to="/" activeClassName='active'><img src={Light} alt="light" /></NavLink>
                <div className='popout'>
                  <p>Lighbulb</p>
                </div>
              </li>
              <li className='disabled'>
                <NavLink exact to="/" activeClassName='active'><img src={Settings} alt="settings" /></NavLink>
                <div className='popout'>
                  <p>Settings</p>
                </div>
              </li>
              <li>
                <NavLink exact to="/" activeClassName='active'><img src={Logout} alt="logout" /></NavLink>
                <div className='popout'>
                  <p>Logout</p>
                </div>
              </li>
            </ul>
            <div className='user-item'>
              <img src={User} alt="logout" />
            </div>
        </div>
      </div>
  )
}
