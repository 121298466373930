import './LoadingSpinner.css'
//IMAGES
import Spinner from '../../assets/img/icons/loading-2.png'

export default function LoadingSpinner() {
  return (
    <div className='loading-spinner'>
      <img src={Spinner} alt="Loading..." />
    </div>
  )
}
