import React, { useEffect, useState } from 'react';
import LoadingTbl from '../../loading/LoadingTbl';
import '../../../pages/cicmarketdata/cicmarketdata.css';
import Info from '../../../assets/img/icons/circle-info-solid.svg'

const InfoTip = ({ message }) => {
  return (
    <div className="info-tip">
      {message}
    </div>
  );
};
export default function CICMarketDataTable(props) {
  const [ToolTipMessage,setToolTipMessage] = useState('')
  const [showTip, setShowTip] = useState(false);
  const { country, denominationtype, startyear, endyear, graphLabels, graphColors } = props;
  const [isloading,setisloading]=useState(false);
  // Function to format numbers with 3 decimal places and add commas
  function HandleShowTip (value,type){
    setShowTip(value);
    if(type === "ATM"){
setToolTipMessage("Number of ATMs per 100,000 adults");
    }else{
      setToolTipMessage("Number of Commercial Bank Branches per 100,000 adults");
    }
  }

  const handleMouseEnter = (e) => {
    const cell = e.target;
    const row = cell.parentNode;
    const rowIndex = row.rowIndex - 1; // Subtract 1 because header row is not included in props.list
    const columnIndex = Array.from(row.cells).indexOf(cell);
    const updateIsHovered = props.updateishovered || (() => {});

    props.updateGraphIndex( columnIndex-1,rowIndex);
    updateIsHovered(true);

  };

  const handleMouseLeave = () => {
    const updateIsHovered = props.updateishovered || (() => {});
    updateIsHovered(false);
  };

  useEffect(()=>{
    if(!props.isloading){
      setisloading(false)
    } else {
      setisloading(true)
    }
   },[props, props.graphLabels]);

   // Find the first Multiplier value
   const Multiplier = props.list.find(element => element.Multiplier !== "1").Multiplier;
  return (
    <>
    {props.list.length === 0 || isloading ? (
        <>
          <LoadingTbl />
        </>
      ) : (
        <table className='CIC-Tabledata table'>
          <label className='denom-title'> <span className='multiplier'>Notes in {Multiplier}</span></label>
          <thead>
            <tr>
              <th>Year</th>
              <th>Total Value</th>
              <th>Total Volume</th>             
              <th>Inflation</th>
              <th onMouseEnter={() => HandleShowTip(true,"ATM")}
                onMouseLeave={() => HandleShowTip(false)}>ATMs<img src={Info} alt="Info" />
                <span className='tooltip'>{ToolTipMessage}</span>
              </th>
              <th onMouseEnter={() => HandleShowTip(true)}
                onMouseLeave={() => HandleShowTip(false)}>
                Bank Branches 
                <img src={Info} alt="Info" />
                <span className='tooltip'>{ToolTipMessage}</span>
              </th>
            </tr>
          </thead>
          <tbody>
          {props.list.map((item, index) => (
              <tr key={index}>
                {Object.entries(item).map(([key, cellData], cellIndex) => {
                  if (key !== 'Multiplier') {
                    if(key === "Inflation")
                    {
                      return (
                        <td key={cellIndex} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                          {cellData+'%'}
                        </td>
                      );
                    }
                    else{
                      return (
                        <td key={cellIndex} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                          {cellData}
                        </td>
                      );
                    }
                    
                  }
                  return null;
                })}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
}
